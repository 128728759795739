import React from "react";

const Checkout4 = () => {
  return (
    <>
      <div className="checkout">
        <iframe
          src="https://nasaiptv.kneo.me/form/127133664e0befdc.html"
          frameborder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Checkout4;
