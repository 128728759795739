import React from "react";

const Checkout3 = () => {
  return (
    <>
      <div className="checkout">
        <iframe
          src="https://nasaiptv.kneo.me/form/127130d0fd38bd5a.html"
          frameborder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Checkout3;
