import React from "react";

const Checkout1 = () => {
  return (
    <>
      <div className="checkout">
        <iframe
          src="https://nasaiptv.kneo.me/form/127131acd8245ef2.html"
          frameborder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Checkout1;
