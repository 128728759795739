import React, { useState, useEffect } from "react";
import PremiumApplication from "../assets/premium_application.png";
import PayPal from "../assets/paypal.png";
import RealTimeDelivery from "../assets/realtimedelivery.png";
import LiveSupport from "../assets/livesupport.png";
import StrongServers from "../assets/strongservers.png";
import HugePackages from "../assets/hugepackages.png";
import HugeDiscounts from "../assets/hugediscounts.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

function Features() {
  let slides;
  const [width, setWidth] = useState(window.innerWidth);
  const updateWindowDimensions = () => {
    const newWidth = window.innerWidth;
    setWidth(newWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, []);

  if (width <= 10000) {
    slides = 3;
  }
  if (width <= 790) {
    slides = 2;
  }
  if (width <= 660) {
    slides = 1;
  }
  return (
    <div
      style={{
        maxHeight: "250vh",
        width: "100vw",
      }}
      id="features"
      className="features-container"
    >
      <div className="intro">
        <h1>Découvrez Nasa IPTV</h1>
        <h3>
          Bon...nous sommes le meilleur choix pour vous, et en voila quelques
          bénéfiques que vous pouvez en bénéficier en nous choisissant comme
          service:
        </h3>
      </div>
      <div className="features">
        <Swiper spaceBetween={50} slidesPerView={slides}>
          <SwiperSlide>
            <div className="feature">
              <img src={PayPal} className="cover-photo" />
              <div className="title">
                <h1>Paiement sécurisé</h1>
              </div>
              <div className="description">
                <p>
                  Pour nous faire confiance, nous utilisons PayPal avec un
                  pourcentage de 0.1% cas de remboursements
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="feature">
              <img src={RealTimeDelivery} className="cover-photo" />
              <div className="title">
                <h1>Livraison instantannée</h1>
              </div>
              <div className="description">
                <p>
                  Notre équipe a dévelopé Nasa IPTV pour un usage facile et une
                  livraison rapide avec des réponses instantnnées à vos
                  réponses.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="feature">
              <img src={LiveSupport} className="cover-photo" />
              <div className="title">
                <h1>Support 24/7</h1>
              </div>
              <div className="description">
                <p>Nasa IPTV donne un 24/7 service clientèle.</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="feature">
              <img src={StrongServers} className="cover-photo" />
              <div className="title">
                <h1>Serveurs Solides</h1>
              </div>
              <div className="description">
                <p>
                  Nasa IPTV donne le meilleur et le plus fort serveur testé
                  juste pour vous et votre appareils.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="feature">
              <img src={HugePackages} className="cover-photo" />
              <div className="title">
                <h1>Grand Packages</h1>
              </div>
              <div className="description">
                <p>
                  Nous offrons +19000 chaines internationales 4K & FHD & HD avec
                  +250 premium chaines adultes and +90000 films/séries
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="feature">
              <img src={HugeDiscounts} className="cover-photo" />
              <div className="title">
                <h1>Grands Promotions</h1>
              </div>
              <div className="description">
                <p>
                  Nous offrons des serveurs premium à -50%+ par comparaison au
                  prix actuel.
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <h3
        style={{ fontSize: "1.9rem", textAlign: "center", marginTop: "2rem" }}
      >
        ← Scrollez à gauche ou à droite pour vois plus... →
      </h3>
    </div>
  );
}

export default Features;
