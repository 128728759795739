import React from "react";

const Checkout2 = () => {
  return (
    <>
      <div className="checkout">
        <iframe
          src="https://nasaiptv.kneo.me/form/1271341a8a48c904.html"
          frameborder="0"
        ></iframe>
      </div>
    </>
  );
};

export default Checkout2;
