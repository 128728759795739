import React, { useState } from "react";

const questions = [
  {
    question:
      "Peux-je utiliser deux appareils en même temps et en une seule connexion?",
    answer:
      "Avec une seule connexion vous pouvez utiliser plusieurs appareils, mais pour éviter le décalage vous devez utiliser une seule connexion seulement à la fois.",
  },
  {
    question: "Quel genre d'appareil supportez-vous?",
    answer:
      "Nous supportons différents types d'appareils comme:  Android Smart Phone, Android Boxes, PC, Smart TV.....",
  },
  {
    question: "Comment peux-je renouveler mon abonnement?",
    answer:
      "Vous pouvez renouveler facilement, choisis juste un package et achetez-le avec en utilisant le même email utilisé lors de la vente du package.",
  },
  {
    question:
      "Parfois quand je suis en train de regarder mon IPTV commence à décaler?",
    answer:
      "Parfois vous pouvez expériencer le décalage, et celà est dû à la pauvre connexion Internet ou bien à la haute demande du traffic. Si vous expériencez le décalage pressez “pause” pour 10-15 secondes et continuez alors à regarder, généralement ça va éliminer le décalage ou au moins diminuer de son intensité.",
  },
];

function FAQ() {
  const [selected, setSelected] = useState(null);

  const toggle = i => {
    if (selected === i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  return (
    <div id="faq" className="faq-container">
      <div className="faq">
        <div className="faq-widget">
          <div className="maintitle">
            <h1>Questions fréquemment posés:</h1>
            <br />
          </div>
          <div className="faq-section">
            {questions.map((item, i) => (
              <div className="item">
                <div className="title" onClick={() => toggle(i)}>
                  <span>{selected === i ? "-" : "+"}</span>
                  <h2>{item.question}</h2>
                </div>
                <div className={selected === i ? "content show" : "content"}>
                  {item.answer}
                </div>
                <br />
                <hr />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
