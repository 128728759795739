import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BsCart3 } from "react-icons/bs";
import { Link } from "react-router-dom";
import "swiper/swiper.min.css";
import TV from "../assets/tv.png";
import Star from "../assets/star.png";
import Trophy from "../assets/trophy.png";

function Plans() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#010221",
        backgroundPosition: `center ${-scrollPosition * 0.7}px`,
        backgroundSize: "cover",
      }}
      id="plans"
      className="plans"
    >
      <div className="title">
        <h1>Choisissez votre plan</h1>
      </div>
      <div className="description">
        <h3>
          Avec 1 Connection vous pouvez utiliser plusieurs appareils . Mais pour
          éviter le décalage il est conseillé d'utiliser une seule connexion à
          la fois.
        </h3>
      </div>
      <div className="plans-list">
        <div className="first-cards">
          <div className="plans-card">
            <div className="plan-name">
              <h1>12 HEURES</h1>
            </div>
            <div className="plans-image">
              <img src={TV} className="my-40 plan-image" />
            </div>
            <div className="plan-price">
              <h3>
                <s className="old-price">€2</s>
              </h3>
              <h1 className="current-price">€1</h1>
            </div>
            <div className="no_connections">1 CONNEXION.</div>
            <div className="plan-outline"></div>
            <div className="caracteritics-plans">
              ✓ Chaines 4K & FHD & HD <br />
              ✓ +19000 Chaines <br />
              ✓ +90000 Films/series <br />
              ✓ Contenu XXX <br />✓ 24/7 Support
            </div>
            <Link to="checkout/2">
              <div className="btn">
                <BsCart3 />
                Acheter
              </div>
            </Link>
          </div>
          <div className="plans-card popular">
            <div className="plan-name">
              <h1>12 MOIS</h1>
            </div>
            <div className="plans-image">
              <img src={Trophy} className="my-40 plan-image" />
            </div>
            <div className="plan-price">
              <h3>
                <s className="old-price">€60</s>
              </h3>
              <h1 className="current-price">€40</h1>
            </div>
            <div className="no_connections">1 CONNECTION.</div>
            <div className="plan-outline"></div>
            <div className="caracteritics-plans">
              ✓ Chaines 4K & FHD & HD <br />
              ✓ +19000 Chaines <br />
              ✓ +90000 Films/Series <br />
              ✓ Contenu XXX <br />✓ 24/7 Support
            </div>
            <Link to="checkout/3">
              <div className="btn btn-popular">
                <BsCart3 />
                Acheter
              </div>
            </Link>
          </div>
          <div className="plans-card">
            <div className="plan-name">
              <h1>6 MOIS</h1>
            </div>
            <div className="plans-image">
              <img src={Star} className="my-40 plan-image" />
            </div>
            <div className="plan-price">
              <h3>
                <s className="old-price">€35</s>
              </h3>
              <h1 className="current-price">€25</h1>
            </div>
            <div className="no_connections">1 CONNECTION.</div>
            <div className="plan-outline"></div>
            <div className="caracteritics-plans">
              ✓ Chaines 4K & FHD & HD <br />
              ✓ +19000 Chaines <br />
              ✓ +90000 Films/Series <br />
              ✓ Contenu XXX <br />✓ 24/7 Support
            </div>
            <Link to="/checkout/4">
              <div className="btn">
                <BsCart3 />
                Acheter
              </div>
            </Link>
          </div>
        </div>
        <div className="last-cards">
          <div className="plans-card">
            <div className="plan-name">
              <h1>24 MOIS</h1>
            </div>
            <div className="plans-image">
              <img src={TV} className="my-40 plan-image" />
            </div>
            <div className="plan-price">
              <h3>
                <s className="old-price">€80</s>
              </h3>
              <h1 className="current-price">€70</h1>
            </div>
            <div className="no_connections">1 CONNECTION.</div>
            <div className="plan-outline"></div>
            <div className="caracteritics-plans">
              ✓ Chaines 4K & FHD & HD <br />
              ✓ +19000 Chaines <br />
              ✓ +90000 Films/Series <br />
              ✓ Contenu XXX <br />✓ 24/7 Support
            </div>
            <Link to="checkout/1">
              <div className="btn">
                <BsCart3 />
                Acheter
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Plans;
